import React, { ComponentProps, FC } from "react";

export const ArrowRight: FC<ComponentProps<"svg">> = (props) => (
  <svg
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.2825 8.86766L10.2872 8.86302L10.863 8.28716C10.9405 8.20972 10.9801 8.10879 10.9819 8.00731C10.9838 7.90095 10.9442 7.79399 10.863 7.71284L10.2872 7.13698L10.2824 7.13227L6.26904 3.11893C6.11046 2.96036 5.85337 2.96036 5.69479 3.11893L5.11893 3.69479C4.96036 3.85337 4.96036 4.11047 5.11893 4.26904L8.84989 8L5.11893 11.731C4.96036 11.8895 4.96036 12.1466 5.11893 12.3052L5.69479 12.8811C5.85337 13.0396 6.11047 13.0396 6.26904 12.8811L10.2825 8.86766Z"
      fill="currentColor"
    />
  </svg>
);
