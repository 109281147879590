import React, { ComponentProps, FC } from "react";

export const ArrowMenuLeft: FC<ComponentProps<"svg">> = (props) => (
  <svg
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.13942 7.13234L5.13471 7.13698L4.55885 7.71284C4.48141 7.79028 4.44179 7.89121 4.43999 7.99269C4.43807 8.09905 4.4777 8.20601 4.55885 8.28716L5.13471 8.86302L5.1395 8.86773L9.15284 12.8811C9.31141 13.0396 9.56851 13.0396 9.72708 12.8811L10.3029 12.3052C10.4615 12.1466 10.4615 11.8895 10.3029 11.731L6.57198 8L10.3029 4.26904C10.4615 4.11047 10.4615 3.85337 10.3029 3.69479L9.72708 3.11893C9.56851 2.96036 9.31141 2.96036 9.15283 3.11893L5.13942 7.13234Z"
      fill="currentColor"
    />
  </svg>
);
