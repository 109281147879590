import React, { FC } from "react";

import Head from "next/head";

import cn from "classnames";

import { Footer } from "@/components/layout/Footer";
import { Header } from "@/components/layout/Header";
import { useAttachBot } from "@/hooks";

import { NewPublicPageLayoutProps } from "./types";

import styles from "./styles.module.scss";

export const NewPublicPageLayout: FC<NewPublicPageLayoutProps> = ({
  children,
  description,
  title,
  keywords,
  dashboardHeaderBg = null,
  headerClassName,
  isCut,
  fullAccessComponent,
  wrapClassName,
  mainClassName,
}) => {
  useAttachBot();

  return (
    <div
      className={cn(
        styles.wrap,
        {
          [styles.wrapCut]: isCut,
        },
        wrapClassName
      )}
    >
      <Head>
        {title && <title>{title}</title>}
        {description && (
          <meta key="description" content={description} name="description" />
        )}
        {keywords && <meta key="keywords" content={keywords} name="keywords" />}
      </Head>

      <Header
        dashboardHeaderBg={dashboardHeaderBg}
        headerClassName={headerClassName}
      />
      <main className={cn(styles.main, mainClassName)}>{children}</main>
      <Footer />
      {fullAccessComponent}
    </div>
  );
};
