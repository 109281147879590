import axios from "axios";
import { QueryClient, QueryFunctionContext, useQuery } from "react-query";

import { WP_API_BASE } from "../constants";
import { WordPressPage } from "./types";

interface Params {
  page?: number;
  categories?: number[] | null;
  per_page?: number;
  categories_exclude?: number[] | null;
  tags?: number[] | null;
  slug?: string;
}

const makeKey = (params: Params) => ["wordpressPages", params] as const;

type ApiFunc<ACF> = (
  context: QueryFunctionContext<ReturnType<typeof makeKey>>
) => Promise<{
  data: Array<WordPressPage<ACF>>;
  totalPages: number;
  total: number;
}>;

export function apiFetchWordPressPages<ACF>({
  queryKey: [_, params],
}: QueryFunctionContext<ReturnType<typeof makeKey>>) {
  return axios
    .get<Array<WordPressPage<ACF>>>(`${WP_API_BASE}/pages`, {
      params,
    })
    .then(({ data, headers }) => ({
      data,
      totalPages: Number(headers["x-wp-totalpages"]),
      total: Number(headers["x-wp-total"]),
    }));
}

export async function apiFetchWordPressPagesInitialData<ACF>(params: Params) {
  const { data, headers } = await axios.get<Array<WordPressPage<ACF>>>(
    `${WP_API_BASE}/pages`,
    {
      params,
    }
  );
  return {
    data,
    totalPages: Number(headers["x-wp-totalpages"]),
    total: Number(headers["x-wp-total"]),
  };
}

export function useWordPressPagesQuery<ACF>(params: Params, initialData?: any) {
  return useQuery(makeKey(params), apiFetchWordPressPages as ApiFunc<ACF>, {
    keepPreviousData: true,
    staleTime: Number.POSITIVE_INFINITY,
    ...(initialData && { initialData }),
  });
}

export function fetchWordPressPages<ACF>(
  queryClient: QueryClient,
  params: Params
) {
  return queryClient.fetchQuery(
    makeKey(params),
    apiFetchWordPressPages as ApiFunc<ACF>
  );
}
export const prefetchWordPressPages = (
  queryClient: QueryClient,
  params: Params
) => queryClient.prefetchQuery(makeKey(params), apiFetchWordPressPages);
