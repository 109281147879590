export const urlProductsList = new Set([
  "Rent Guarantee Insurance",
  "Home Emergency Cover",
  "Energy Performance Certificate (EPC)",
  "Gas Safety",
  "Property Photography",
  "Electrical Installation Condition Report (EICR)",
  "Inventory Check-In & Check-Out",
  "Tenant Referencing",
  "Tenants Contents Insurance",
]);
