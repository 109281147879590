import React, { useMemo, useState } from "react";

import Link from "next/link";

import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import cn from "classnames";

import LogoMobile from "@/uikit2/assets/Logo";

import { Logo } from "./assets/Logo";
import { Navigation } from "./components/Navigation";
import { UserActions } from "./components/UserActions";
import { HeaderProps } from "./types";
import { getScrollTopPosition } from "./utils/getScrollTopPosition";

import styles from "./styles.module.scss";

// TODO: Refactoring
// TODO: Assets should be svg icons, not components
export const Header = ({
  headerClassName,
  dashboardHeaderBg,
  scrollWithPage,
  transparentOnTop,
  initialData,
}: HeaderProps) => {
  const [showMobileMenu, setShowMobile] = useState<boolean>(false);
  const [activeTab, setTab] = useState<string | undefined>();
  const [isScroll, setIsScroll] = useState(getScrollTopPosition() > 0);
  const headerStyle = useMemo(
    () => ({
      backgroundColor:
        transparentOnTop && !isScroll
          ? "transparent"
          : dashboardHeaderBg ?? "var(--colors-pageBadge)",
    }),
    [dashboardHeaderBg, transparentOnTop, isScroll]
  );

  useScrollPosition(
    ({ currPos }) => {
      currPos.y > 0 ? setIsScroll(true) : setIsScroll(false);
    },
    undefined,
    undefined,
    true,
    100
  );

  return (
    <header
      className={cn(
        styles.header,
        {
          [styles.scrollWithPage]: scrollWithPage,
        },
        headerClassName
      )}
      style={headerStyle}
    >
      <div className={styles.container}>
        <div className={styles.logo}>
          <Link href="/">
            <a aria-label="Mashroom">
              <span className={styles.logoDesktop}>
                <Logo />
              </span>
              <span className={styles.logoMobile}>
                <LogoMobile />
              </span>
            </a>
          </Link>
        </div>
        <Navigation
          activeTab={activeTab}
          initialData={initialData}
          onMobileMenu={setShowMobile}
          onTab={setTab}
          showMobileMenu={showMobileMenu}
        />
        {/*<UserActions*/}
        {/*  isNavigationOpened={Boolean(activeTab) || showMobileMenu}*/}
        {/*/>*/}
      </div>
    </header>
  );
};
