import React from "react";

import Image from "next/image";

import { FOOTER_PARTNERS } from "./constants";

import styles from "./styles.module.scss";

export const Partners = () => (
  <div className={styles.root} data-testid="footer-partners">
    {FOOTER_PARTNERS.map((partner, key) => (
      <div key={key} className={styles.logo}>
        <Image alt={partner.alt} src={partner.src} />
      </div>
    ))}
  </div>
);
