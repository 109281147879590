import React, { ComponentProps, FC } from "react";

export const Logo: FC<ComponentProps<"svg">> = ({ ...rest }) => (
  <svg
    fill="none"
    height="44"
    viewBox="0 0 59 44"
    width="59"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M51.946 42.37c-2.367 2.434-43.798 1.896-45.658 0-1.86-1.895-8.032-20.29-5.813-24.922C2.715 12.817 22.987-.043 29.413 0c6.426.065 26.803 12.45 29.191 17.448 2.03 4.286-4.29 22.51-6.658 24.922Z"
      fill="#FF6B6B"
    />
    <g fill="#FCF8EF" filter="url(#headerLogo)">
      <path d="M29.244 24.015c0 10.752 5.235 13.325 12.4 12.95 2.353-.131 5.109-1.23 7.144-3.473.212-.22.297-.396.106-.55-.191-.176-.34-.066-.53.154a8.913 8.913 0 0 1-6.741 3.1c-3.01 0-5.448-1.473-6.995-3.43-1.802-2.287-2.586-5.717-2.565-8.949v-4.2c0-2.946-1.314-4.507-3.477-4.507-2.12 0-3.306 1.473-3.942 3.034-.085.198-.233.154-.297-.176-.424-1.869-1.611-2.858-3.307-2.858-1.971 0-3.18 1.253-3.794 2.704-.403.924-.34-.044-.34-.044v-.989S17.014 15 15.445 15s-1.442 1.781-1.442 1.781v9.52s0 1.628 1.442 1.628c1.463 0 1.441-1.627 1.441-1.627l-.02-4.486c0-2 .847-4.969 2.67-5.54.276-.089.53-.133.763-.133.954 0 1.4.748 1.4 2.837v7.322s0 1.627 1.42 1.627c1.399 0 1.399-1.627 1.399-1.627V21.75c0-2 .741-4.903 2.586-5.497.275-.088.53-.132.763-.132.954 0 1.399.748 1.399 2.837l-.021 5.057Z" />
      <path d="M44.684 27.891c-1.36 0-1.337-1.829-1.337-1.829-.373.871-1.733 1.938-3.575 1.938S36 26.977 36 24.777c0-1.85 1.14-2.352 3.333-2.961 1.886-.523 4.035-1.11 4.035-3.419 0-1.677-1.03-2.678-3.004-2.678-1.316 0-2.193.479-2.631 1.11-.264.392 0 .436.263.283.175-.087.372-.152.614-.152.79 0 1.337.588 1.337 1.372 0 .805-.614 1.437-1.469 1.437-.855 0-1.71-.588-1.71-1.851 0-1.807 1.754-2.918 4.144-2.918C44.114 15 46 16.96 46 20.117v5.923c0 .457 0 1.851-1.316 1.851Zm-5.965-3.397c0 1.916.965 2.591 2.018 2.591 1.47 0 2.456-1.393 2.588-2.177v-3.79s.043-.565-.439-.151c-.439.413-1.097.762-1.93 1.067-1.864.696-2.237 1.524-2.237 2.46Z" />
    </g>
    <defs>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height="27.477"
        id="headerLogo"
        width="40.477"
        x="11.261"
        y="12.341"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy=".08" />
        <feGaussianBlur stdDeviation="1.369" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_23023_2677"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_23023_2677"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
