import approved from "./assets/approved.svg";
import localheroes from "./assets/localheroes.svg";
import mibp from "./assets/MIBP.svg";
import moneyshield from "./assets/moneyshield.svg";
import openbank from "./assets/openbank.svg";
import prop from "./assets/prop.svg";
import rics from "./assets/rics.svg";
import tenancyDeposit from "./assets/tenancy_deposit.svg";
import token from "./assets/token.svg";

export const FOOTER_PARTNERS = [
  {
    src: tenancyDeposit,
    alt: "Tenancy deposit",
  },
  {
    src: moneyshield,
    alt: "Money shield",
  },
  {
    src: localheroes,
    alt: "Local heroes",
  },
  {
    src: token,
    alt: "Token",
  },
  {
    src: approved,
    alt: "Approved code",
  },
  {
    src: mibp,
    alt: "MIBP",
  },
  {
    src: prop,
    alt: "Property ombudsman",
  },
  {
    src: openbank,
    alt: "Open banking",
  },
  {
    src: rics,
    alt: "RICS",
  },
];
