import React from "react";

const Arrow = () => (
  <svg
    fill="none"
    height="9"
    viewBox="0 0 14 9"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.93167 7.21018C5.93355 7.21212 5.93546 7.21405 5.93738 7.21597L6.64647 7.92507C6.74182 8.02042 6.86611 8.06921 6.99107 8.07143C7.12203 8.07379 7.25374 8.025 7.35367 7.92507L8.06276 7.21598C8.06471 7.21402 8.06665 7.21206 8.06856 7.21008L13.0104 2.26821C13.2057 2.07295 13.2057 1.75637 13.0104 1.56111L12.3013 0.852013C12.1061 0.656751 11.7895 0.656751 11.5942 0.852013L7.00007 5.44618L2.40591 0.852014C2.21065 0.656752 1.89406 0.656752 1.6988 0.852015L0.989708 1.56111C0.794446 1.75637 0.794446 2.07295 0.989708 2.26821L5.93167 7.21018Z"
      fill="#0E2C54"
    />
  </svg>
);

export default Arrow;
