import React from "react";

import Link from "next/link";

import { ListItemProps } from "./types";

import styles from "../../styles.module.scss";

export const ListItem = ({
  price,
  href,
  title,
  onClickLink,
}: ListItemProps) => {
  const handleClick = () => onClickLink(href);

  return (
    <li>
      <Link href={href} prefetch={false}>
        <a className={styles.listLink} onClick={handleClick}>
          <span className={styles.linkText}>{title}</span>
          {Boolean(price) && <span className={styles.price}>{price}</span>}
        </a>
      </Link>
    </li>
  );
};
