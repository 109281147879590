import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { useRouter } from "next/router";

import cn from "classnames";
import { last } from "lodash-es";

import { Button } from "@/components/ui";
import { useIsLoggedIn } from "@/core/Auth/hooks";
import { useWordPressPagesQuery } from "@/entities/wordpress/api/pages";
import { dataLayerViewAndSelectItemEvent } from "@/lib/dataLayersPush";
import { AuthUrlService } from "@/lib/services";

import { ArrowMenuLeft } from "../../assets/ArrowMenuLeft";
import { Card, ListItem } from "./components";
import { urlProductsList } from "./constants";
import { useGetProductsListQuery } from "./graphql/GetProductsList.generated";
import { Acf, NavigationProps, Navigations } from "./types";

import styles from "./styles.module.scss";

export const Navigation = ({
  activeTab,
  onTab,
  showMobileMenu,
  onMobileMenu,
  initialData,
}: NavigationProps) => {
  const isILoggedIn = useIsLoggedIn();
  const router = useRouter();
  const timeout = useRef<NodeJS.Timeout | null>(null);
  const timeoutOpen = useRef<NodeJS.Timeout | null>(null);
  const [sections, setSections] = useState<Navigations | undefined>();
  const { data, status } = useWordPressPagesQuery<Acf>(
    { slug: "index-3-0" },
    initialData && { data: initialData, totalPages: 1, total: 1 }
  );

  const navigationSections = useMemo(() => data?.data[0]?.acf.sections, [data]);

  // const { data: productsData } = useGetProductsListQuery();
  // const productList = useMemo(
  //   () =>
  //     (productsData?.products?.list?.extra_services ?? []).filter(
  //       (product) => product.page_url
  //     ),
  //   [productsData?.products?.list?.extra_services]
  // );
  const isActive = activeTab != null;
  const exceptionListAnalytics = ["Mortgage Advice", "Property Management"];
  const activeExceptionAnalytics =
    typeof activeTab === "string" &&
    !exceptionListAnalytics.includes(activeTab);

  useEffect(() => {
    if (!sections && status === "success") {
      setSections(navigationSections as Navigations);
    }
  }, [navigationSections, sections, status]);

  useEffect(() => {
    if (
      window.matchMedia("(min-width: 1024px)").matches &&
      isActive &&
      activeExceptionAnalytics
    ) {
      dataLayerViewAndSelectItemEvent({
        action: "view_item_list",
        products: [],
      });
    }
  }, [activeExceptionAnalytics, isActive, []]);

  const navigation = useMemo(() => {
    if (sections == null) {
      return [];
    }

    return [
      {
        title: sections.landlord_insurance_title,
        cta: sections.landlord_insurance,
      },
      {
        title: sections.mortgage_advice_title,
        cta: sections.mortgage_advice,
      },
      {
        title: sections.tenant_find_title,
        cta: sections.tenant_find,
      },
      {
        title: sections.property_management_title,
        cta: sections.property_management,
      },
      {
        title: sections.for_renters_title,
        cta: sections.for_renters,
      },
    ];
  }, [sections]);

  const selectedProductsList = useMemo(() => {
    if (sections == null) {
      return [];
    }
    return [
      ...sections.landlord_insurance
        .filter(
          ({ section }) =>
            urlProductsList.has(section.section_title) ||
            section.questions.find((question) =>
              urlProductsList.has(question.question_text)
            )
        )
        .map((el) => el.section),
      ...(sections.tenant_find
        .find(({ section }) => section.button_text === "Pricing")
        ?.section.questions.filter(({ question_text }) =>
          urlProductsList.has(question_text.trim())
        ) ?? []),
      ...sections.for_renters
        .filter(({ section }) => urlProductsList.has(section.section_title))
        .map((el) => el.section),
    ].map((el) => {
      if (el["button_text"]) {
        const [price] = el["button_text"].match(/\d{1,4}/gm) ?? [];
        return {
          price: price ? Number(price) : undefined,
          title: String(el["section_title"]),
          page_url: String(el["button_url"]),
        };
      } else if (el["question_text"]) {
        const [price] = el["price"].match(/\d{1,4}/gm) ?? [];
        return {
          price: Number(price),
          title: String(el["question_text"]),
          page_url: String(el["question_url"]),
        };
      }
      return {};
    });
  }, [sections]);

  useEffect(() => {
    const viewingProductPath = last(router?.pathname.split("/"));
    // const viewingProduct = productList.filter(
    //   (product) =>
    //     viewingProductPath && product.page_url?.includes(viewingProductPath)
    // );
    const filteredProductsList = selectedProductsList.filter(
      (product) =>
        viewingProductPath && product.page_url?.includes(viewingProductPath)
    );

    filteredProductsList.length > 0 &&
      dataLayerViewAndSelectItemEvent({
        action: "view_item",
        products: filteredProductsList,
      });
  }, [router?.pathname, [], selectedProductsList]);

  const handleMouseEnter = (
    _event?: React.MouseEvent<HTMLElement, MouseEvent>,
    tab?: string
  ) => {
    if (window.matchMedia("(max-width: 1023px)").matches) {
      return;
    }

    if (timeoutOpen.current) {
      clearTimeout(timeoutOpen.current);
    }

    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }

    timeoutOpen.current = setTimeout(
      () => {
        tab && onTab(tab);
      },
      isActive ? 0 : 150
    );
  };

  const handleMouseLeave = (
    _event?: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    if (window.matchMedia("(max-width: 1023px)").matches) {
      return;
    }

    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    if (timeoutOpen.current) {
      clearTimeout(timeoutOpen.current);
      timeoutOpen.current = null;
    }

    timeout.current = setTimeout(() => {
      onTab(undefined);
    }, 250);
  };

  const handleClickMenuItem = useCallback(
    (_event: React.MouseEvent<HTMLElement, MouseEvent>, tab?: string) => {
      if (window.matchMedia("(min-width: 1024px)").matches) {
        return;
      }

      if (activeTab === tab) {
        onTab(undefined);
      } else {
        onTab(tab);
      }
    },
    [activeTab, onTab]
  );

  const handleMenuToggle = useCallback(() => {
    onMobileMenu(!showMobileMenu);
    if (!showMobileMenu && activeExceptionAnalytics) {
      dataLayerViewAndSelectItemEvent({
        action: "view_item_list",
        products: [],
      });
    }

    if (activeTab != null) {
      onTab(undefined);
    }
  }, [
    activeTab,
    activeExceptionAnalytics,
    onMobileMenu,
    onTab,
    showMobileMenu,
    [],
  ]);

  const handleClickLink = useCallback(() => {
    onTab(undefined);
    onMobileMenu(false);
  }, [onMobileMenu, onTab]);

  const handleSelected = useCallback(
    (url: string) => {
      const filteredProductsList = selectedProductsList.filter((product) =>
        product.page_url?.includes(url)
      );

      dataLayerViewAndSelectItemEvent({
        action: "select_item",
        products: filteredProductsList,
      });

      onTab(undefined);
      onMobileMenu(false);
    },
    [onMobileMenu, onTab, selectedProductsList]
  );

  return (
    <>
      <button
        className={cn(styles.menuToggleButton, {
          [styles.menuToggleButtonClose]: showMobileMenu,
        })}
        onClick={handleMenuToggle}
        title="toggle mobile menu"
        type="button"
      >
        <i />
        <i />
        <i />
      </button>
      <nav
        className={cn(styles.navigation, {
          [styles.showMobileNavigation]: showMobileMenu,
        })}
      >
        <ul className={styles.navigationList}>
          {!isILoggedIn ? (
            <li className={styles.navigationListButton}>
              <Button
                fullWidth
                nextLinkProps={{
                  href: AuthUrlService.getCreateAccountUrl(router?.asPath),
                }}
                shape="rounded"
                size="md"
                theme="primary"
              >
                Join Mashroom
              </Button>
            </li>
          ) : null}

          {navigation.map(({ title }) => {
            const className = {
              [styles.navigationListItemActive]: activeTab === title,
              [styles.navigationListItemDisabled]:
                activeTab != null && activeTab !== title,
            };

            return (
              <React.Fragment key={title}>
                <li
                  className={cn(
                    styles.navigationListItem,
                    styles.navigationListItemDesktop,
                    className
                  )}
                  onMouseEnter={(e) => handleMouseEnter(e, title)}
                  onMouseLeave={handleMouseLeave}
                >
                  {title}
                </li>

                <li
                  className={cn(
                    styles.navigationListItem,
                    styles.navigationListItemMobile,
                    className
                  )}
                >
                  <div
                    className={styles.navigationListItemButton}
                    onClick={(e) => handleClickMenuItem(e, title)}
                    role="button"
                    tabIndex={0}
                  >
                    {title}
                  </div>
                </li>
              </React.Fragment>
            );
          })}
        </ul>

        <div
          className={styles.navigationMobileBack}
          onClick={handleClickLink}
          role="button"
          tabIndex={-1}
        />

        <div
          className={cn(styles.dropdown as string, {
            [styles.dropdownLandlord]: activeTab === navigation[0]?.title,
            [styles.dropdownManagement]: activeTab === navigation[3]?.title,
            [styles.dropdownMortgage]: activeTab === navigation[1]?.title,
            [styles.dropdownRenter]: activeTab === navigation[4]?.title,
            [styles.dropdownTenant]: activeTab === navigation[2]?.title,
            [styles.dropdownVisible]: activeTab != null,
          })}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className={styles.navigationContainer}>
            <div className={styles.navigationContent}>
              {navigation.map(({ cta, title }) => {
                if (activeTab !== title) {
                  return null;
                }

                return (
                  <React.Fragment key={title}>
                    <div
                      className={styles.menuClose}
                      onClick={handleClickMenuItem}
                      role="button"
                      tabIndex={0}
                    >
                      <ArrowMenuLeft />
                      Main menu
                    </div>
                    <div className={cn(styles.row, styles.rowDesktop)}>
                      {cta.map((col) => (
                        <div
                          key={col.section.section_title}
                          className={styles.column}
                        >
                          <Card
                            buttonText={col.section.button_text}
                            description={col.section.section_description}
                            href={col.section.button_url}
                            onClickLink={handleSelected}
                            title={col.section.section_title}
                          />
                        </div>
                      ))}
                    </div>
                    <div className={styles.row}>
                      {cta.map((col) => (
                        <div
                          key={col.section.section_title}
                          className={styles.column}
                        >
                          <Card
                            buttonText={col.section.button_text}
                            description={col.section.section_description}
                            href={col.section.button_url}
                            onClickLink={handleSelected}
                            title={col.section.section_title}
                          />

                          <ul className={styles.list}>
                            {col.section.questions.map((link) => (
                              <ListItem
                                key={`${link.question_url}_${link.question_text}`}
                                href={link.question_url}
                                onClickLink={handleSelected}
                                price={link.price}
                                title={link.question_text}
                              />
                            ))}
                          </ul>
                        </div>
                      ))}
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};
