import React, { useState } from "react";

import Link from "next/link";

import classNames from "classnames";
import { CSSTransition } from "react-transition-group";

import Arrow from "./Arrow";

import css from "./index.module.css";

const cn = classNames.bind(css);

type Props = {
  header: string;
  links: Array<{
    title: string;
    href: string;
  }>;
};

const Dropdown = ({ header, links }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => {
    setIsOpen((prevState) => !prevState);
  };
  return (
    <>
      <div className={css.container} onClick={handleOpen}>
        <span className={css.header}> {header}</span>
        <div className={cn(css.icon, { [css.down]: isOpen })}>
          <Arrow />
        </div>
      </div>
      <CSSTransition
        className={css.links}
        classNames={{
          enter: css.linksEnter,
          enterActive: css.linksEnterActive,
          exit: css.linksExit,
          exitActive: css.linksExitActive,
        }}
        in={isOpen}
        timeout={200}
        unmountOnExit
      >
        <div>
          {links.map((link, index) => (
            <Link key={index} href={link.href}>
              <a>{link.title}</a>
            </Link>
          ))}
        </div>
      </CSSTransition>
    </>
  );
};

export default Dropdown;
