import React, { FC } from "react";

import dynamic from "next/dynamic";

import { useIsLoggedIn, useIsUserDataLoading } from "@/core/Auth/hooks";
import { AuthPageEmbeddedProps } from "@/features/auth/components/AuthPageEmbedded";

import { NewPublicPageLayout } from "../NewPublicPageLayout";
import { NewPrivatePageLayoutProps } from "./types";

const AuthPageEmbedded = dynamic<AuthPageEmbeddedProps>(
  () =>
    import("@/features/auth/components/AuthPageEmbedded").then(
      (module) => module.AuthPageEmbedded
    ),
  {
    ssr: false,
  }
);

export const NewPrivatePageLayout: FC<NewPrivatePageLayoutProps> = (props) => {
  const isLoggedIn = useIsLoggedIn();
  const isCurrentUserLoading = useIsUserDataLoading();

  if (isCurrentUserLoading) {
    return null;
  }

  if (!isLoggedIn) {
    return (
      <AuthPageEmbedded startWithCreateAnAccount={false} useRedirect={false} />
    );
  }

  return <NewPublicPageLayout {...props} />;
};
