import React, { FC } from "react";

import Link from "next/link";

import { SITE_COPYRIGHT, SOCIAL_MEDIA_LINKS } from "@/constants";

import {
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
} from "../../../features/common/components/legacy/icons";
import Links from "./Links/Links";
import { LEGAL_INFO } from "./locales";
import { Partners } from "./Partners";
import { FooterProps } from "./types";

import styles from "./styles.module.scss";

export const Footer: FC<FooterProps> = ({ withRegulatoryNotice = true }) => (
  <div className={styles.root}>
    <Links />
    <hr className={styles.line} />
    <Partners />
    <div>
      <div className={styles.mobile}>
        <hr />
        <div className={styles.linkRowBottom}>
          <div>
            <Link href="/right-to-rent" prefetch={false}>
              <a>Right to rent</a>
            </Link>
            <Link as="/p/privacy-policy" href="/p/[page]" prefetch={false}>
              <a>Privacy policy</a>
            </Link>
          </div>
          <div>
            <Link
              as="/p/terms-and-conditions"
              href="/p/[page]"
              prefetch={false}
            >
              <a>Term of service</a>
            </Link>
            <Link href="/sitemap" prefetch={false}>
              <a>Sitemap</a>
            </Link>
          </div>
        </div>
        <div className={styles.bottomGrid}>
          <div
            className={styles.mashroom}
            dangerouslySetInnerHTML={{ __html: SITE_COPYRIGHT }}
          />
          <div className={styles.social}>
            <a
              href={SOCIAL_MEDIA_LINKS.twitter}
              rel="noopener noreferrer"
              target="_blank"
            >
              <TwitterIcon />
            </a>
            <a
              href={SOCIAL_MEDIA_LINKS.facebook}
              rel="noopener noreferrer"
              target="_blank"
            >
              <FacebookIcon />
            </a>
            <a
              href={SOCIAL_MEDIA_LINKS.instagram}
              rel="noopener noreferrer"
              target="_blank"
            >
              <InstagramIcon />
            </a>
          </div>
        </div>
      </div>
      {withRegulatoryNotice && (
        <div
          className={styles.legalInfo}
          dangerouslySetInnerHTML={{ __html: LEGAL_INFO }}
        />
      )}
      <div className={styles.flex}>
        <div
          className={styles.mashroom}
          dangerouslySetInnerHTML={{ __html: SITE_COPYRIGHT }}
        />
        <div className={styles.linkRowBottom}>
          <Link as="/p/terms-and-conditions" href="/p/[page]" prefetch={false}>
            <a>Term of service</a>
          </Link>
          <Link as="/p/privacy-policy" href="/p/[page]" prefetch={false}>
            <a>Privacy policy</a>
          </Link>
          <Link href="/right-to-rent" prefetch={false}>
            <a>Right to rent</a>
          </Link>
        </div>
      </div>
    </div>
  </div>
);
