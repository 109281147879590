import React from "react";

import Link from "next/link";

import { ArrowRight } from "../../../../assets/ArrowRight";
import { CardProps } from "./types";

import styles from "../../styles.module.scss";

export const Card = ({
  href,
  title,
  description,
  buttonText,
  onClickLink,
}: CardProps) => (
  <Link href={href} passHref prefetch={false}>
    <a className={styles.card} onClick={() => onClickLink(href)}>
      <h3 className={styles.cardTitle}>{title}</h3>
      <p className={styles.cardDescription}>{description}</p>
      <div className={styles.cardLink}>
        <span>{buttonText}</span>
        <ArrowRight />
      </div>
    </a>
  </Link>
);
